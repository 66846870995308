import axios from "axios";
import { TARGET_API, buildURL } from "./utils";
import { DemographicInfo, PersonalInfo, ProfessionalInfo, UpdateProfile, Redeem } from "../model/user";
import { SigninFormValues, UpdatedFormValues } from "../model/user";
import { Answers } from "../store/dashboard/types";

const params = {
  typeSlug: "master-list",
  page: 1,
  recPerPage: 500,
  q: "",
  parent: "",
};

export const sendOTP = async (
  email?: string,
  // phone?: string,
  password?: string,
  // occupationID?: number,
  // countryID?: number
) => {
  const url = "auth/signup/send-otp/";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    email,
    // phone,
    password,
    // occupationID,
    // countryID,
    source: "web",
  });
  return response?.data;
};

export const verifyOTP = async (
  email: string | undefined,
  otp: number | null,
  accessToken: string,
  token: string | undefined
) => {
  const url = "auth/signup/verify-otp/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      email,
      otp,
      accessToken,
      source: "web",
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};

export const sendOTPForOffEmail = async (
  token: string | undefined
) => {
  const url = "user/send-official-email-verification-otp/";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), 
    {}, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  return response?.data;
};


export const verifyOTPForOffEmail = async (
  otp: number | null,
  accessToken: string,
  token: string | undefined
) => {
  const url = "user/verify-official-email-otp/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      otp,
      accessToken,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};


export const getSocialUser = async (
  token: string | undefined,
  platform: string | undefined,
  source: string | undefined,
  consent: boolean | false
) => {
  const url = "auth/social/get-user/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      platform,
      source,
      consent
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};

export const getCountry = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "country",
  });
  return response?.data?.payload;
};
export const getLanguage = async (countryID: string | undefined) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "language",
    countryID,
  });
  return response?.data?.payload;
};
export const getGender = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "gender",
  });
  return response?.data?.payload;
};
export const getAge = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "age-group",
  });
  return response?.data?.payload;
};
export const getEducation = async (countryID: number | undefined) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "education",
    countryID: countryID !== undefined ? countryID : null,
  });
  return response?.data?.payload;
};
export const getOccupationType = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "occupation-type",
  });
  return response?.data?.payload;
};
export const getIncomeGroup = async (currency: string | undefined) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "income-group" + "-" + currency?.toLocaleLowerCase(),
  });
  return response?.data?.payload;
};
export const getMonthlyIncome = async (countryID: number | undefined) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "monthly-income",
    countryID: countryID !== undefined ? countryID : null,
  });
  return response?.data?.payload;
};
export const getRelationship = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "relationship",
  });
  return response?.data?.payload;
};
export const getFamilyMembers = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "family-member-count",
  });
  return response?.data?.payload;
};

export const getIndustryList = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "industry",
  });
  return response?.data?.payload;
};

export const getIndustrySubTypeList = async (parentID:Number) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "industry-sub-type",
    parent:parentID
  });
  return response?.data?.payload;
};

export const getIndustryProfessionList = async (parentID:Number) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "profession",
    parent: parentID
  });
  return response?.data?.payload;
};

export const getEmployeeSizeList = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "employee-size",
  });
  return response?.data?.payload;
};
export const getRevenueSizeList = async (countryID: number | undefined) => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "company-revenue",
    countryID: countryID !== undefined ? countryID : null,
  });
  return response?.data?.payload;
};
export const getJobTitleList = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "titles",
  });
  return response?.data?.payload;
};
export const getDepartmentsList = async () => {
  const url = "master-data/list";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    ...params,
    typeSlug: "departments",
  });
  return response?.data?.payload;
};
export const saveUserInfo = async (
  userInfo: PersonalInfo,
  token: string | undefined
) => {
  const url = "user/save-info/";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), userInfo, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};

export const updateUserProfile = async(
  userProfile: UpdateProfile,
  token: string | undefined
) => {
  const url = "user/update-account/";

  const response = await axios.put(buildURL(url, TARGET_API.BASE), userProfile, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};

export const updateReferralCode = async(
  referralCode: string | undefined,
  token: string | undefined
) => {
  const url = "user/update-referral";
  const rC = {
    referralCode: referralCode
  };
  const response = await axios.put(buildURL(url, TARGET_API.BASE), rC, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};

export const requestRedeem = async(
  redeemData: Redeem,
  token: string | undefined
) => {
  const url = "transaction/save-redeem";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), redeemData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};

export const saveUserDemo = async (
  userDemographicInfo: DemographicInfo,
  token: string | undefined
) => {
  const url = "user/save-demographic-info/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    userDemographicInfo,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};

export const saveUserprofessionalInfo = async (
  userProfessionalInfo: ProfessionalInfo,
  token: string | undefined
) => {
  const url = "user/save-professional-info/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    userProfessionalInfo,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};

export const login = async (
  signinFormValues: UpdatedFormValues
) => {
  const url = "auth/login/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    signinFormValues
  );
  return response?.data;
};
export const logout = async (token: string | undefined) => {
  const url = "auth/logout/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getProfile = async (token: string | undefined) => {
  const url = "user/get-profile/";

  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const updateProfile = async (
  key: string | undefined,
  value: string | undefined,
  token: string | undefined
) => {
  const url = "user/update-profile?key=" + key + "&value=" + value;
  const response = await axios.put(
    buildURL(url, TARGET_API.BASE),
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getProfileQuestions = async (token: string | undefined) => {
  const url = "user/get-profile-questions/";

  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getPostalCode = async (
  countryID: number | undefined,
  postalCode: string | undefined,
  token: string | undefined
) => {
  const url =
    "common/get-postal-code-details?countryID=" +
    countryID +
    "&postalCode=" +
    postalCode;

  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getCategory = async (token: string | undefined) => {
  const url = "category/list/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    { page: 1, recPerPage: 500, q: "" },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getProducts = async (
  categoryID: number,
  token: string | undefined
) => {
  const url = "products/list/";

  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    { page: 1, recPerPage: 500, q: "", categoryID },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getSurveys = async (token: string | undefined) => {
  const url = "survey/list/?page=1&recPerPage=500";

  const response = await axios.get(
    buildURL(url, TARGET_API.BASE),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getAllSurveys = async (token: string | undefined) => {
  const url = "user/survey-history/?page=1&recPerPage=500";

  const response = await axios.get(
    buildURL(url, TARGET_API.BASE),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getDonations = async (token: string | undefined) => {
  const url = "donation/list";

  const response = await axios.get(
    buildURL(url, TARGET_API.BASE),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getCoupons = async (token: string | undefined) => {
  const url = "coupon/list";

  const response = await axios.get(
    buildURL(url, TARGET_API.BASE),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getQuestions = async (
  productID: number,
  token: string | undefined
) => {
  const url = "products/question-list/?id=" + productID;

  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getXOXODaySSOURL = async(campaignName:string | undefined, token: string | undefined) => {
  const url = "external/xoxoday-sso-link?campaignName=" + campaignName;

  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};

export const saveAnswers = async (
  answer: Answers,
  token: string | undefined
) => {
  const url = "products/save-answer";

  const response = await axios.post(buildURL(url, TARGET_API.BASE), answer, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const resetPasswordSendOTP = async (email: string | undefined) => {
  const url = "auth/reset-password/send-otp";
  const response = await axios.post(buildURL(url, TARGET_API.BASE), {
    email,
    source: "web",
  });
  return response?.data;
};
export const resetPasswordVerifyOTP = async (
  email: string | undefined,
  otp: number | null,
  accessToken: string,
  token: string | undefined
) => {
  const url = "auth/reset-password/verify-otp/";
  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      email,
      otp,
      accessToken,
      source: "web",
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const updateResetChangePassword = async (
  oldPassword: string | null,
  newPassword: string,
  token: string | undefined
) => {
  const url = "user/update-password/";
  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const deleteAccountSendOtp = async (
  email: string | undefined,
  token: string | undefined
) => {
  const url = "auth/delete-account/send-otp";
  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      email,
      source: "web",
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const deleteAccountVerifyOtp = async (
  email: string | undefined,
  otp: number | null,
  accessToken: string | undefined,
  token: string | undefined
) => {
  const url = "auth/delete-account/verify-otp/";
  const response = await axios.post(
    buildURL(url, TARGET_API.BASE),
    {
      email,
      otp,
      accessToken,
      source: "web",
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response?.data;
};
export const getUserAnalytics = async (token: string | undefined) => {
  const url = "dashboard/get-user-analytics/";
  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getAnalytics = async (token: string | undefined) => {
  const url = "transaction/get-analytics/";
  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getTransactionHistory = async (
  token: string | undefined,
  fromDate: number,
  toDate: number,
  page: number,
  recPerPage: number
) => {
  const url =
    "user/get-transaction-history" +
    "?page=" +
    page +
    "&recPerPage=" +
    recPerPage +
    "&fromDate=" +
    fromDate +
    "&toDate=" +
    toDate;
  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
export const getPointHistory = async (
  token: string | undefined,
  fromDate: number,
  toDate: number,
  page: number,
  recPerPage: number
) => {
  const url =
    "user/get-points-history" +
    "?page=" +
    page +
    "&recPerPage=" +
    recPerPage +
    "&fromDate=" +
    fromDate +
    "&toDate=" +
    toDate;
  const response = await axios.get(buildURL(url, TARGET_API.BASE), {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response?.data;
};
