import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import MySurveys from "../Widgets/MySurveys";
import ProfileStatus from "../Widgets/ProfileStatus";
import { ButtonContainer, NextButtonStyled } from "../Signup/styledComponent";
import { 
  getProfile, 
  getCountry,
  getAge,
  getEducation,
  getGender,
  getIncomeGroup,
  getMonthlyIncome,
  getRelationship,
  getDepartmentsList,
  getEmployeeSizeList,
  getIndustryList,
  getIndustrySubTypeList,
  getIndustryProfessionList,
  getJobTitleList,
  getRevenueSizeList,
  getPostalCode,
  updateUserProfile,
  sendOTPForOffEmail,
  verifyOTPForOffEmail
} from "../../api";
import { ResponseSchema, UpdateProfile, PersonalInfo, GeoInfo, ProfileInfo } from "../../model/user";
import { setProfile, setProfileLoaded } from "../../store/profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import { GlobalState } from "../../store/types";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";
import timeSlot from "../../assets/json/time-slot.json";
import { set } from "lodash";
const DATE_FORMAT = "DD-MM-YYYY HH:MM:SS";

const Profile = () => {
  const isUpdate = true;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: GlobalState) => state.auth);
  const profile = useSelector((state: GlobalState) => state.profile);
  const [countryList, setCountryList] = useState<ResponseSchema[]>([]);
  const [genderList, setGenderList] = useState<ResponseSchema[]>([]);
  const [ageList, setAgeList] = useState<ResponseSchema[]>([]);
  const [educationList, setEducationList] = useState<ResponseSchema[]>([]);
  const [monthlyIncomeList, setMonthlyIncomeList] = useState<ResponseSchema[]>([]);
  const [incomeGroupList, setIncomeGroupList] = useState<ResponseSchema[]>([]);
  const [relationshipList, setRelationshipList] = useState<ResponseSchema[]>([]);
  const [industryList, setIndustryList] = useState<ResponseSchema[]>([]);
  const [industrySubTypeList, setIndustrySubTypeList] = useState<ResponseSchema[]>([]);
  const [professionList, setProfessionList] = useState<ResponseSchema[]>([]);  
  const [employeeSizeList, setEmployeeSizeList] = useState<ResponseSchema[]>([]);
  const [revenueSizeList, setRevenueSizeList] = useState<ResponseSchema[]>([]);
  const [jobTitleList, setJobTitleList] = useState<ResponseSchema[]>([]);
  const [departmentsList, setDepartmentsList] = useState<ResponseSchema[]>([]);
  const [showOffEmail, setShowOffEmail] = useState(false);
  const [showBoardlineNumber, setShowBoardlineNumber] = useState(false);
  const [isOffEmailVerified, setOffEmailVerified] = useState(false);
  const [isBoardlineVerified, setBoardlineVerified] = useState(false);
  const [vMode, setVerifyMode] = useState('');
  const [showOffEmailOTP, setShowOffEmailOTP] = useState(false);
  const [offEmailOTPSent, setOffEmailOTPSent] = useState(false);
  const [emailOTPAccToken, setEmailOTPAccToken] = useState('');
  const [appDate, setAppDate] = useState(moment());
  const [rejectionReason, setRejectionReason] = useState('');

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  const getIndustrySubTypes = (val:number)=>{
    getIndustrySubTypeList(val).then((professions: ResponseSchema[]) => setIndustrySubTypeList(professions));
  }

  const getProfessions = (val:number)=>{
    getIndustryProfessionList(val).then((professions: ResponseSchema[]) => setProfessionList(professions));

  }
  useEffect(
    () => {
      const countryID = Number(profile.userProfile?.geoInfo?.countryID);
    getCountry().then((countryList: ResponseSchema[]) => { setCountryList(countryList);});
    getIndustryList().then((industryList: ResponseSchema[]) => setIndustryList(industryList));
    getEmployeeSizeList().then((employeeSizeList: ResponseSchema[]) => setEmployeeSizeList(employeeSizeList));
    getJobTitleList().then((jobTitleList: ResponseSchema[]) => setJobTitleList(jobTitleList));
    getDepartmentsList().then((departmentsList: ResponseSchema[]) => setDepartmentsList(departmentsList));
    getGender().then((genderList: ResponseSchema[]) => setGenderList(genderList));
    getAge().then((ageList: ResponseSchema[]) => setAgeList(ageList));
    getRelationship().then((relationshipList: ResponseSchema[]) => setRelationshipList(relationshipList));

    const token = Cookies.get("token");
    if (token || auth.token) {
      getProfile(auth.token ? auth.token : token)
        .then((resp) => {
          if (resp?.status === 1) {
            dispatch(setProfile(resp?.payload));
          } else {
            if (
              resp?.messageCode === "ACCOUNT_DELETED" ||
              resp?.messageCode === "TOKEN_EXPIRED"
            ) {
              Swal.fire(resp?.message).then(() => {navigate("/login")});
            } else Swal.fire(resp?.message);
          }
        })
        .finally(() => dispatch(setProfileLoaded(true)));
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  useEffect(
    () => {
      // const currency = profile.userProfile?.geoInfo?.currency; 
      // getIncomeGroup(currency).then((incomeGroupList: ResponseSchema[]) => setIncomeGroupList(incomeGroupList));
      const countryID = Number(profile.userProfile?.geoInfo?.countryID);
      getMonthlyIncome(countryID).then((monthlyIncomeList: ResponseSchema[]) => setMonthlyIncomeList(monthlyIncomeList));
      getRevenueSizeList(countryID).then((revenueSizeList: ResponseSchema[]) => setRevenueSizeList(revenueSizeList));
      getEducation(countryID).then((ageList: ResponseSchema[]) => setEducationList(ageList)); 
      setShowOffEmail(profile.userProfile?.profileInfo?.offVerificationMode === "email" ? true : false);
      setShowBoardlineNumber(profile.userProfile?.profileInfo?.offVerificationMode === "boardline" ? true : false);
      setOffEmailVerified(Number(profile.userProfile?.profileInfo?.offEmailVerifiedOn) > 0 ? true : false); 
      setBoardlineVerified(Number(profile.userProfile?.profileInfo?.boardlineVerifiedOn) > 0 ? true : false);

      setShowOffEmailOTP(profile.userProfile?.profileInfo?.offVerificationMode === "email" && profile.userProfile?.profileInfo?.offEmailAddress?.trim() !== "" ? true : false);
      setRejectionReason(profile.userProfile?.profileInfo?.rejectionReason !== undefined ? profile.userProfile?.profileInfo?.rejectionReason : "");
      
    }, [profile.userProfile?.geoInfo?.currency]
  );

  useEffect(
    () => {
      let industryID = 0;
      if(profile?.userProfile?.profileInfo?.industryID) industryID = parseInt(profile?.userProfile?.profileInfo?.industryID);
      if(industryID != 0 ) getIndustrySubTypes(industryID)
    }, [profile?.userProfile?.profileInfo?.industryID]
  );

  useEffect(
    () => {
      let industrySubTypeID = 0;
      if(profile?.userProfile?.profileInfo?.industrySubTypeID) industrySubTypeID = parseInt(profile?.userProfile?.profileInfo?.industrySubTypeID);
      if(industrySubTypeID != 0 ) getProfessions(industrySubTypeID)
    }, [profile?.userProfile?.profileInfo?.industrySubTypeID]
  );

  

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight:"100vh",
        backgroundRepeat: "repeat-y",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="app-content main-content">
              <div className="side-app">
                <section>
                  <div className="row mt-1">
                    <div className="col-12 col-lg-6 main_content_gts">
                      <h1 className="pageHaeader">Update Profile</h1>
                    </div>
                    <div className="col-12 col-lg-6 mt-1">
                      <ProfileStatus wc={w} />  
                    </div>
                  </div>
                  <MySurveys />
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      firstName: isUpdate ? profile.userProfile?.personalInfo?.firstName : "",
                      lastName: isUpdate ? profile.userProfile?.personalInfo?.lastName : "",
                      email: isUpdate ? profile.userProfile?.personalInfo?.email : "",
                      mobile: isUpdate ? profile.userProfile?.personalInfo?.phone : "",

                      country: isUpdate ? profile.userProfile?.geoInfo?.countryID : "",
                      postalCode: isUpdate ? profile.userProfile?.geoInfo?.postalCode : "",
                      cityName: isUpdate ? profile.userProfile?.geoInfo?.cityName : "",

                      gender: isUpdate ? profile.userProfile?.profileInfo?.genderID : "",
                      age: isUpdate ? profile.userProfile?.profileInfo?.ageGroupID : "",
                      education: isUpdate ? profile.userProfile?.profileInfo?.educationID : "",
                      income: isUpdate ? profile.userProfile?.profileInfo?.incomeGroupID : "",
                      relationship: isUpdate ? profile.userProfile?.profileInfo?.relationshipID : "",

                      industry: isUpdate ? profile.userProfile?.profileInfo?.industryID : "",
                      industrySubType: isUpdate ? profile.userProfile?.profileInfo?.industrySubTypeID : "",
                      profession: isUpdate ? profile.userProfile?.profileInfo?.professionID : "",
                      people: isUpdate ? profile.userProfile?.profileInfo?.employeeSizeID : "",
                      revenue: isUpdate ? profile.userProfile?.profileInfo?.revenueSizeID : "",
                      jobTitle: isUpdate ? profile.userProfile?.profileInfo?.titleID : "",
                      department: isUpdate ? profile.userProfile?.profileInfo?.departmentID : "",
                      companyName: isUpdate ? profile.userProfile?.profileInfo?.companyName : "",
                      officialEmail: isUpdate ? profile.userProfile?.profileInfo?.offEmailAddress : "",

                      verificationStatus: isUpdate ? profile.userProfile?.profileInfo?.verificationStatus : "",
                      offVerificationMode: isUpdate ? profile.userProfile?.profileInfo?.offVerificationMode : "",
                      boardlineNumber: isUpdate ? profile.userProfile?.profileInfo?.boardlineNumber : "",
                      extension: isUpdate ? profile.userProfile?.profileInfo?.extension : "",
                      appointmentDate: isUpdate ? profile.userProfile?.profileInfo?.appointmentDate : "",
                      appointmentTime: isUpdate ? profile.userProfile?.profileInfo?.appointmentTime : "",
                      rejectionReason: isUpdate ? profile.userProfile?.profileInfo?.rejectionReason : "",

                      otp1: null,
                      otp2: null,
                      otp3: null,
                      otp4: null,
                      otp5: null,
                      otp6: null,
                    }}
                    validate={(values) => {
                      const errors: FormikErrors<{
                        // firstName: string | undefined;
                        // lastName: string | undefined;
                        email: string | undefined;
                        mobile: string | undefined;
                        country: string | undefined;
                        postalCode: string | undefined;
                        cityName: string | undefined;

                        gender: string | undefined;
                        age: string | undefined;
                        education: string | undefined;
                        income: string | undefined;
                        relationship: string | undefined;

                        industry: string | undefined;
                        people: string | undefined;
                        revenue: string | undefined;
                        jobTitle: string | undefined;
                        department: string | undefined;
                        companyName: string | undefined;
                        officialEmail: string | undefined;

                        verificationStatus: string | undefined;
                        offVerificationMode: string | undefined;
                        boardlineNumber: string | undefined;
                        extension: string | undefined;
                        appointmentDate: string | undefined;
                        appointmentTime: string | undefined;
                      }> = {};
                      // if (!values.firstName?.trim()) {
                      //   errors.firstName = "First Name is required";
                      // }
                      // if (!values.lastName?.trim()) {
                      //   errors.lastName = "Last Name is required";
                      // }
                      if (!values.mobile?.trim()) {
                        errors.mobile = "Mobile is required";
                      }
                      if (!values.country) {
                        errors.country = "Country is required";
                      }
                      if (!values.postalCode) {
                        errors.postalCode = "Postal Code is required";
                      }
                      if (!values.cityName) {
                        errors.cityName = "City Name is required";
                      }
                      if(profile.userProfile.userType === 'b2b'){
                        if (!values.industry) {
                          errors.industry = "Industry is required";
                        }
                        if (!values.people) {
                          errors.people = "Employee Strength is required";
                        }
                        if (!values.revenue) {
                          errors.revenue = "Annual Turnover is required";
                        }
                        if (!values.jobTitle) {
                          errors.jobTitle = "Designation is required";
                        }
                        if (!values.department) {
                          errors.department = "Department is required";
                        }
                      } else {
                        if (!values.gender) {
                          errors.gender = "Gender is required";
                        }
                        if (!values.age) {
                          errors.age = "Age Group is required";
                        }
                        if (!values.education) {
                          errors.education = "Education is required";
                        }
                        if (!values.income) {
                          errors.income = "Annual Income is required";
                        }
                        if (!values.relationship) {
                          errors.relationship = "Relationship Status is required";
                        }
                      }
                      return errors;
                    }}
                    onSubmit={( values, { setSubmitting }) => {
                      setSubmitting(false);
                      let updateInfo: UpdateProfile;
                      let pInfo: PersonalInfo;
                      let pfInfo: ProfileInfo;
                      let gInfo: GeoInfo;
                      pInfo = {
                        firstName: values?.firstName,
                        lastName: values?.lastName,
                        phone: values?.mobile
                      };
                      pfInfo = {
                        genderID: values?.gender,
                        ageGroupID: values?.age,
                        incomeGroupID: values?.income,
                        relationshipID: values?.relationship,
                        educationID: values?.education,
                        industryID: values?.industry,
                        industrySubTypeID: values?.industrySubType,
                        professionID: values?.profession,
                        employeeSizeID: values?.people,
                        titleID: values?.jobTitle,
                        revenueSizeID: values?.revenue,
                        departmentID: values?.department,
                        companyName: values?.companyName,
                        offEmailAddress: vMode === "email" ? values?.officialEmail : "",
                        verificationStatus: 0,
                        rejectionReason: values?.rejectionReason,
                        profilePercentage:0,
                        offVerificationMode: vMode,
                        boardlineNumber: vMode === "boardline" ? values?.boardlineNumber : "",
                        extension: vMode === "boardline" ? values?.extension : "",
                        appointmentDate: vMode === "boardline" ? appDate.format("MM/DD/YYYY").toString() : "",
                        appointmentTime: vMode === "boardline" ? values?.appointmentTime : "",
                        offEmailVerifiedOn: 0,
                        boardlineVerifiedOn: 0,
                      };
                      gInfo = {
                        postalCode: values?.postalCode,
                        cityName: values?.cityName,
                      };
                      updateInfo = {
                        personalInfo: pInfo,
                        profileInfo: pfInfo,
                        geoInfo: gInfo
                      };
                      const token = Cookies.get("token");
                      updateUserProfile(updateInfo, auth.token ? auth.token : token).then(
                        (resp) => {
                          if (resp?.status === 1) {
                            if (isUpdate && token) {
                              Swal.fire("Your information is saved successfully.");
                              getProfile(token)
                                .then((resp) => {
                                  if (resp?.status === 1) {
                                    dispatch(setProfile(resp?.payload));
                                    setShowOffEmailOTP(resp?.payload?.profileInfo?.offVerificationMode === "email" && resp?.payload?.profileInfo?.offEmailAddress?.trim() !== "" ? true : false);
                                  } else {
                                    if (
                                      resp?.messageCode === "ACCOUNT_DELETED" ||
                                      resp?.messageCode === "TOKEN_EXPIRED"
                                    ) {
                                      Swal.fire(resp?.message).then(() =>
                                        navigate("/login")
                                      );
                                    } else Swal.fire(resp?.message);
                                  }
                                })
                                .finally(() => dispatch(setProfileLoaded(true)));
                            }
                          } else {
                            Swal.fire(resp?.message);
                          }
                        }
                      );
                    }}
                  >
                    {({ values, handleChange, handleBlur, isValid, dirty, setFieldValue, setFieldError, }) => (
                      <Form className={isUpdate ? "" : ""}>
                        <span className="secHeader">Personal Info</span>
                        <div className="row">
                        {isUpdate && (
                          <>
                              <div className="col-12 col-lg-6 mb-3">
                                <Field
                                  name="firstName"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstName}
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                />
                              </div>
                              <div className="col-12 col-lg-6 mb-3">
                                <Field
                                  className="form-control"
                                  name="lastName"
                                  type="text"
                                  placeholder="Enter Last Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                />
                              </div>
                              <div className="col-12 col-lg-6 mb-3">
                                <Field
                                  className="form-control"
                                  value={values.email}
                                  disabled
                                />
                              </div>
                              <div className="col-12 col-lg-6 mb-3">
                                <Field
                                  className="form-control"
                                  name="mobile"
                                  maxLength={10}
                                  placeholder="Enter Mobile Number"
                                  onChange={handleChange}
                                  onKeyDown={handleChange}
                                  onBlur={handleBlur}
                                  value={values.mobile}
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                />
                              </div>
                          </>
                        )}
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              as="select"
                              name="country"
                              placeholder="Select Country"
                              className="form-control"
                              onBlur={handleBlur}
                              disabled
                            >
                              <option value="" disabled selected hidden>
                                Select Country
                              </option>
                              {countryList?.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              name="postalCode"
                              type="text"
                              className="form-control"
                              placeholder="Enter Postal Code"
                              onChange={async (e: any) => {
                                handleChange("postalCode")(e);
                                const val = e?.currentTarget?.value;
                                if (val?.length === 6) {
                                  const postalResp = await getPostalCode(
                                    Number(values?.country),
                                    val,
                                    auth.token ? auth.token : Cookies.get("token")
                                  );
                                  if (postalResp?.status === 1)
                                    setFieldValue("cityName", postalResp?.payload?.city);
                                  else {
                                    setFieldError("cityName", postalResp?.message);
                                  }
                                }
                              }}
                              onKeyDown={handleChange}
                              onBlur={handleBlur}
                              value={values.postalCode}
                              maxLength={6}
                              disabled
                            />
                            <ErrorMessage
                              name="postalCode"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              name="cityName"
                              type="text"
                              className="form-control"
                              placeholder="Enter City Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cityName}
                              disabled
                            />
                            <ErrorMessage
                              name="cityName"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                        </div>
                        <span className="secHeader">More About Me</span>
                        <div className="row">
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              as="select"
                              name="gender"
                              placeholder="Select Gender"
                              className="form-control mt-auto"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected hidden>
                                Your Gender
                              </option>
                              {genderList?.map((gender) => (
                                <option key={gender.id} value={gender.id} {...profile}>
                                  {gender.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="gender"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              as="select"
                              name="age"
                              placeholder="Select Age"
                              className="form-control mt-auto"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected hidden>
                                Your Age Group
                              </option>
                              {ageList?.map((age) => (
                                <option key={age.id} value={age.id}>
                                  {age.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="age"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              as="select"
                              name="education"
                              placeholder="Highest Degree Received"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected hidden>
                                Highest Degree Received
                              </option>
                              {educationList?.map((education) => (
                                <option key={education.id} value={education.id}>
                                  {education.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="education"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                            <Field
                              as="select"
                              name="income"
                              placeholder="Select Income"
                              className="form-control mt-auto"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected hidden>
                                Monthly Income
                              </option>
                              {monthlyIncomeList?.map((income) => (
                                <option key={income.id} value={income.id}>
                                  {income.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="income"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">
                          <Field
                              as="select"
                              name="relationship"
                              placeholder="Select Relationship"
                              className="form-control mt-auto"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected hidden>
                                Your Relationship Status
                              </option>
                              {relationshipList?.map((relationship) => (
                                <option key={relationship.id} value={relationship.id}>
                                  {relationship.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="relationship"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="col-12 col-lg-4 mb-3">

                          </div>
                        </div>
                        {(
                          () => {
                            if(profile.userProfile.userType === 'b2b'){
                              return (
                                <>
                                  <span className="secHeader">Professional Info</span>
                                  <div className="row">
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        as="select"
                                        name="industry"
                                        placeholder="Select Industry"
                                        className="form-control"
                                        onBlur={handleBlur}
                                        onChange={async (e: any) => {
                                          getIndustrySubTypes(e.target.val)
                                          handleChange(e);
                                        }}
                                      >
                                        <option value="" disabled selected hidden>
                                          Industry You Work In
                                        </option>
                                        {industryList?.map((industry) => (
                                          <option key={industry.id} value={industry.id}>
                                            {industry.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="industry"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                    {/* Healthcare type */}
                                    {
                                      (values['industry'] == "662") && 
                                      <div className="col-12 col-lg-4 mb-3">
                                        <Field
                                          as="select"
                                          name="industrySubType"
                                          placeholder="Select industry sub type"
                                          className="form-control"
                                          onBlur={handleBlur}
                                          onChange={async (e: any) => {
                                            getProfessions(e.target.value)
                                            handleChange(e);
                                          }}
                                        >
                                          <option value="" disabled selected hidden>
                                            Healthcare Industry Type
                                          </option>
                                          {industrySubTypeList?.length > 0 && industrySubTypeList?.map((heathCares) => (
                                            <option key={heathCares.id} value={heathCares.id}>
                                              {heathCares.name}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="healthcareType"
                                          component="div"
                                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                        />
                                      </div>                                       
                                    }

                                    {/* Healthcare more specification */}
                                    {                                      
                                      (values['industry'] == "662") && 
                                      <div className="col-12 col-lg-4 mb-3">
                                        <Field
                                          as="select"
                                          name="profession"
                                          placeholder="Select Industry"
                                          className="form-control"
                                          onBlur={handleBlur}
                                          onChange={async (e: any) => {
                                            handleChange(e);
                                          }}
                                        >
                                          <option value="" disabled selected hidden>
                                            Profession
                                          </option>
                                          {professionList?.length > 0 && professionList?.map((profession) => (
                                            <option key={profession.id} value={profession.id}>
                                              {profession.name}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="healthcareType"
                                          component="div"
                                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                        />
                                      </div>
                                    }

                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        as="select"
                                        name="people"
                                        placeholder="Select Employee Size"
                                        className="form-control"
                                        onBlur={handleBlur}
                                      >
                                        <option value="" disabled selected hidden>
                                          Employee Strength of Your Company
                                        </option>
                                        {employeeSizeList?.map((employeeSize) => (
                                          <option key={employeeSize.id} value={employeeSize.id}>
                                            {employeeSize.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="people"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        as="select"
                                        name="revenue"
                                        placeholder="Select Revenue Size"
                                        className="form-control mt-auto"
                                        onBlur={handleBlur}
                                      >
                                        <option value="" disabled selected hidden>
                                          Annual Turnover of Your Company
                                        </option>
                                        {revenueSizeList?.map((revenue) => (
                                          <option key={revenue.id} value={revenue.id}>
                                            {revenue.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="revenue"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        as="select"
                                        name="jobTitle"
                                        placeholder="Select Designation"
                                        className="form-control mt-auto"
                                        onBlur={handleBlur}
                                      >
                                        <option value="" disabled selected hidden>
                                          Your Designation
                                        </option>
                                        {jobTitleList?.map((jobTitle) => (
                                          <option key={jobTitle.id} value={jobTitle.id}>
                                            {jobTitle.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="jobTitle"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        as="select"
                                        name="department"
                                        placeholder="Select Department"
                                        className="form-control"
                                        onBlur={handleBlur}
                                      >
                                        <option value="" disabled selected hidden>
                                          Department You Work In
                                        </option>
                                        {departmentsList?.map((department) => (
                                          <option key={department.id} value={department.id}>
                                            {department.name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="department"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                  </div>
                                  <span className="secHeader">Employment Info</span>
                                  <div className="row">
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        name="companyName"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Company Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.companyName}
                                      />
                                      <ErrorMessage
                                        name="companyName"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                    </div>
                                  </div>
                                  {
                                    (
                                      () => {
                                        if(!isOffEmailVerified && !isBoardlineVerified){
                                          return (
                                            <>
                                              <div className="row">
                                                <div className="col-12">
                                                  <p className="text-white">How do you want to verify your professional/employment info?</p>
                                                </div>
                                                <div className="col-3">
                                                  <input
                                                    type="radio"
                                                    name="offVerificationMode"
                                                    id="divShowEmail"
                                                    className="mr-2 mb-auto mt-1"
                                                    role="button"
                                                    value="email"
                                                    onChange={() => {
                                                        setVerifyMode('email');
                                                        setShowOffEmail(true);
                                                        setShowBoardlineNumber(false);
                                                      }
                                                    }
                                                    checked={(showOffEmail ? true : false)}
                                                    disabled={isBoardlineVerified || isOffEmailVerified ? true : false}
                                                  />
                                                  <label
                                                    role="button"
                                                    className="text-break text-white"
                                                    htmlFor="divShowEmail"
                                                    id="labelShowEmail"
                                                  >
                                                    Official Email
                                                  </label>
                                                </div>
                                                <div className="col-3">
                                                  <input
                                                    type="radio"
                                                    name="offVerificationMode"
                                                    id="divShowBoardline"
                                                    className="mr-2 mb-auto mt-1"
                                                    role="button"
                                                    value="boardline"
                                                    onChange={() => {
                                                        setVerifyMode('boardline');
                                                        setShowOffEmail(false);
                                                        setShowBoardlineNumber(true)
                                                      }
                                                    }
                                                    checked={(showBoardlineNumber ? true : false)}
                                                    disabled={isBoardlineVerified || isOffEmailVerified ? true : false}
                                                  />
                                                  <label
                                                    role="button"
                                                    className="text-break text-white"
                                                    htmlFor="divShowBoardline"
                                                    id="labelShowBoardline"
                                                  >
                                                    Boardline Number
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        } else {
                                          return(
                                            <>
                                              <div className="row">
                                                <div className="col-12">
                                                  <p className="text-white">You have successfully verified your professional/employment info using<b>{isOffEmailVerified ? " Offical Email" : ""} {isBoardlineVerified ? "Boardline Number" : ""}</b></p>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      }
                                    )()
                                  }
                                  <div className={"row " + (showOffEmail ? 'showDiv' : 'hideDiv')}>
                                    <div className="col-12 col-lg-4 mb-3">
                                      <Field
                                        name="officialEmail"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Official Email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.officialEmail}
                                        disabled={isOffEmailVerified || (showOffEmailOTP && offEmailOTPSent) ? true : false}
                                      />
                                      <ErrorMessage
                                        name="officialEmail"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                      <div className="text-orange mt-2">
                                        <b>
                                        <i
                                          className={isOffEmailVerified ? "fal fa-check-circle" : ""}
                                          aria-hidden="true"
                                          ></i> {isOffEmailVerified ? "Verified" : ""}
                                        </b>
                                      </div>
                                    </div>
                                    <div className={"col-12 col-lg-8 mb-3 " + (!isOffEmailVerified && showOffEmailOTP && !offEmailOTPSent ? 'showDiv' : 'hideDiv')}>
                                      <p className="text-white">Are you sure your official email is correct? <span className="mt-2" style={{ cursor: "pointer", color: "#FF5630", fontWeight: "bold"}} onClick={() => {
                                        const token = Cookies.get("token");
                                        sendOTPForOffEmail(
                                          token
                                        )
                                        .then((resp) => {
                                          if (resp?.status === 1) {
                                            setOffEmailOTPSent(true);
                                            setEmailOTPAccToken(resp?.payload?.accessToken);
                                          } else {
                                            Swal.fire(resp?.message);
                                          }
                                        });
                                    }}>Yes, send me OTP</span></p>
                                    </div>
                                    <div className={"col-12 col-lg-6 mb-3 " + (!isOffEmailVerified && showOffEmailOTP && offEmailOTPSent ? 'showDiv' : 'hideDiv')}>
                                      <p className="smFS alnL">
                                        To continue further, you need to enter valid OTP that has been sent your official email address
                                      </p>
                                      <div className="pf-form__textfield d-flex">
                                        <Field
                                          className="form-control otpTxt mr-4"
                                          name="otp1"
                                          id="otp1"
                                          type="text"
                                          maxLength={1}
                                          onPaste={(e: any) => {
                                            const otp = e?.clipboardData?.getData("text");
                                            if (!isNaN(otp)) {
                                              const otpArray = otp?.split("");
                                              values.otp1 = otpArray[0];
                                              values.otp2 = otpArray[1];
                                              values.otp3 = otpArray[2];
                                              values.otp4 = otpArray[3];
                                              values.otp5 = otpArray[4];
                                              values.otp6 = otpArray[5];
                                            }
                                          }}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            if (e.target.value) e?.target?.nextSibling?.focus();
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp1}
                                        />
                                        <Field
                                          className="form-control otpTxt mr-4"
                                          name="otp2"
                                          id="otp2"
                                          type="text"
                                          maxLength={1}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            if (e.target.value) e?.target?.nextSibling?.focus();
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp2}
                                        />
                                        <Field
                                          className="form-control otpTxt mr-4"
                                          name="otp3"
                                          id="otp3"
                                          type="text"
                                          maxLength={1}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            if (e.target.value) e?.target?.nextSibling?.focus();
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp3}
                                        />
                                        <Field
                                          className="form-control otpTxt mr-4"
                                          name="otp4"
                                          id="otp4"
                                          type="text"
                                          maxLength={1}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            if (e.target.value) e?.target?.nextSibling?.focus();
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp4}
                                        />
                                        <Field
                                          className="form-control otpTxt mr-4"
                                          name="otp5"
                                          id="otp5"
                                          type="text"
                                          maxLength={1}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            if (e.target.value) e?.target?.nextSibling?.focus();
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp5}
                                        />
                                        <Field
                                          className="form-control otpTxt"
                                          name="otp6"
                                          id="otp6"
                                          type="text"
                                          maxLength={1}
                                          onChange={(e: any) => {
                                            e.target.value = e.target.value.replace(
                                              /[a-z]/gi,
                                              ""
                                            );
                                            handleChange(e);
                                          }}
                                          onKeyUp={(e: any) =>
                                            e.key === "Backspace"
                                              ? e.target?.previousSibling?.focus()
                                              : ""
                                          }
                                          onBlur={handleBlur}
                                          value={values.otp6}
                                        />
                                      </div>
                                      <ErrorMessage
                                        name="otp6"
                                        component="div"
                                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                      />
                                      <span className="mt-2" style={{ cursor: "pointer", color: "#FF5630", fontWeight: "bold"}} onClick={() => {
                                        const token = Cookies.get("token");
                                        const otp =
                                          "" +
                                          values.otp1 +
                                          values.otp2 +
                                          values.otp3 +
                                          values.otp4 +
                                          values.otp5 +
                                          values.otp6;
                                        verifyOTPForOffEmail(
                                          Number(otp),
                                          emailOTPAccToken,
                                          token
                                        )
                                        .then((resp) => {
                                          if (resp?.status === 1) {
                                            Swal.fire(resp?.message);
                                            setOffEmailOTPSent(false);
                                            setShowOffEmailOTP(false);
                                            setOffEmailVerified(true);
                                          } else {
                                            Swal.fire(resp?.message);
                                          }
                                        });
                                    }}>Verify OTP</span>
                                    </div>
                                  </div>
                                  <div className={(showBoardlineNumber ? 'showDiv' : 'hideDiv')}>
                                    <div className="row w-100">
                                      <div className="col-12 col-lg-5 mb-3">
                                        <Field
                                          name="boardlineNumber"
                                          type="text"
                                          maxLength="16"
                                          className="form-control"
                                          placeholder="Enter Your Boardline Number"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.boardlineNumber}
                                          disabled={isBoardlineVerified ? true : false}
                                        />
                                        <ErrorMessage
                                          name="linkedInID"
                                          component="div"
                                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                        />
                                        <div className="text-orange mt-2">
                                          <b>
                                          <i
                                          className={isBoardlineVerified ? "fal fa-check-circle" : ""}
                                          aria-hidden="true"
                                          ></i> {isBoardlineVerified ? "Verified" : ""}
                                          </b>
                                        </div>
                                        <div className="text-red mt-2">
                                          <b>
                                          <i
                                          className={rejectionReason !== null ? "fal fa-times-circle" : ""}
                                          aria-hidden="true"
                                          ></i> {rejectionReason !== null ? "Rejected - " + rejectionReason : ""}
                                          </b>
                                        </div>
                                      </div>
                                      <div className="col-12 col-lg-2 mb-3">
                                        <Field
                                          name="extension"
                                          type="text"
                                          className="form-control"
                                          placeholder="Extension"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.extension}
                                          disabled={isBoardlineVerified ? true : false}
                                          maxLength="5"
                                        />
                                        <ErrorMessage
                                          name="linkedInID"
                                          component="div"
                                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                        />
                                      </div>
                                      <div className="col-12 col-lg-2 mb-3">
                                        <DatePicker
                                          selected={appDate?.toDate()}
                                          minDate={appDate?.toDate()}
                                          onChange={(date: any) => {setAppDate(moment(date))}}
                                          className="form-control w100px"
                                          name="appointmentDate"
                                          disabled={isBoardlineVerified ? true : false}
                                        />
                                      </div>
                                      <div className="col-12 col-lg-3 mb-3">
                                        <Field
                                          as="select"
                                          name="appointmentTime"
                                          placeholder="Preferred Time"
                                          className="form-control"
                                          disabled={isBoardlineVerified ? true : false}
                                        >
                                          <option value="" disabled selected hidden>
                                            Preferred Time
                                          </option>
                                          {timeSlot?.map((tm) => (
                                          <option key={tm.id} value={tm.value}>
                                            {tm.value}
                                          </option>
                                          ))}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          }
                        )()}
                        <ButtonContainer>
                          <NextButtonStyled
                            type="submit"
                            className="btnOrngGrd btn btn-lg btn-rect btn-right"
                            disabled={!(dirty && isValid)}
                          >
                            Update Profile
                          </NextButtonStyled>
                        </ButtonContainer>
                      </Form>
                    )}
                  </Formik>
                  <>
                    <hr className="secSeparator"></hr>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="border divRnd d-flex p-2 bg-light">
                          <div className="p-2">
                            <div>
                              <b>Change Password</b>
                            </div>
                            <div>You can reset or change password by clicking here.</div>
                          </div>
                          <div className="p-2">
                            <button className="btn btn-lg btn-rect btn-right border border-primary">
                              <NavLink to="/account/change">Change</NavLink>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="border divRnd d-flex p-2 bg-light">
                          <div className="p-2">
                            <b>Remove Account</b>
                            <div>Once you delete your account, there is no going back.</div>
                          </div>
                          <div className="p-2">
                            <button className="btn btn-lg btn-rect btn-right border border-danger">
                              <NavLink to="/account/delete" className={"text-delete"}>
                                Delete
                              </NavLink>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
