import React, { useEffect, useMemo, useState } from "react";
import bg from "../../assets/images/background1-min.png";
import { useNavigate } from "react-router-dom";
import {
  DynamicQuestion,
  FieldType,
  PersonalInfo,
  ResponseSchema,
} from "../../model/user";
import {
  getProfile,
  getOccupationType,
  getCountry,
  getPostalCode,
  getProfileQuestions,
  saveUserInfo,
  updateProfile,
  updateReferralCode,
  logout,
} from "../../api";
import { setProfile, setProfileLoaded } from "../../store/profile/profileSlice";
import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { ContinueStyled, SignUpButtonStyled } from "../Signup/styledComponent";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../store/types";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

const ProfileInfo = () => {
  const [totalQuestions, setTotalQuestions] = useState<number>(6);
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const profile = useSelector((state: GlobalState) => state.profile);
  const auth = useSelector((state: GlobalState) => state.auth);
  const [countryList, setCountryList] = useState<ResponseSchema[]>([]);
  const [occupationTypeList, setOccupationTypeList] = useState<ResponseSchema[]>([]);
  const [isZipCodeReadOnly, setZipCodeReadOnly] = useState(false);
  const [isLocationForm, setIsLocationForm] = useState(true);
  const [isQuestionForm, setIsQuestionForm] = useState(false);
  const [isThankYouForm, setIsThankYouForm] = useState(false);
  const [questions, setQuestions] = useState<DynamicQuestion[]>([]);
  const [formValues, setFormValues] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCountry().then((countryList: ResponseSchema[]) => {setCountryList(countryList)});
    getOccupationType().then((occupationTypeList: ResponseSchema[]) => setOccupationTypeList(occupationTypeList));
  
    const token = Cookies.get("token");
    if (token || auth.token) {
      getProfile(auth.token ? auth.token : token)
        .then((resp) => {
          if (resp?.status === 1) {
            dispatch(setProfile(resp?.payload));
          } else {
            if (
              resp?.messageCode === "ACCOUNT_DELETED" ||
              resp?.messageCode === "TOKEN_EXPIRED"
            ) {
              Swal.fire(resp?.message).then(() => {});
            } else Swal.fire(resp?.message);
          }
        })
        .finally(() => dispatch(setProfileLoaded(true)));
    } else {
      navigate("/get-started");
      Swal.fire("You are not logged in. Please login or signup!");
    }
  }, [auth.token]);

  useEffect(() => {
    if (isQuestionForm) {
      getProfileQuestions(Cookies.get("token") || auth.token).then((resp) => {
        setQuestions(resp?.payload);
        setTotalQuestions(resp?.payload?.length + 1);
      });
    }
  }, [isQuestionForm]);

  const onChangeFields = (
    type: FieldType,
    e: any,
    q: DynamicQuestion,
    key?: number
  ) => {
    let value: any = {};
    switch (type) {
      case FieldType.CHECKBOX:
        if (e?.currentTarget?.checked) {
          if (value[q.variableName]) {
            value[q.variableName] = value?.[q.variableName]?.add(key);
          } else {
            value[q.variableName] = new Set([key]);
          }
        } else {
          if (value?.[q.variableName]?.has(key)) {
            value[q.variableName]?.delete(key);
          }
        }
        break;
      case FieldType.DROPDOWN:
        value[q.variableName] = e?.currentTarget?.value;
        break;
      case FieldType.RADIO:
        value[q.variableName] = key;
        break;
      case FieldType.TEXT:
        value[q.variableName] = e?.currentTarget?.value;
        break;
      default:
        value[q.variableName] = e?.currentTarget?.value;
        break;
    }
    setFormValues(value);
  };

  const progressBar = useMemo(() => {
    const isQuestionTab = isQuestionForm ? 1 : 0;
    const pc = 100 / (totalQuestions + 1);
    const width = pc * (activeQuestion + isQuestionTab + 1);
    return (
      <div
        className="progress w-50 ml-auto mr-auto mt-3"
        style={{ height: 15, borderRadius: 15 }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${width}%`,
            backgroundColor: "#FBB03B",
            borderRadius: 15,
          }}
          aria-valuenow={width}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    );
  }, [totalQuestions, activeQuestion, isQuestionForm]);

  return (
    <div
      className="d-flex flex-column bg-col-two"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="d-flex justify-content-center pt-3">
        <img
          src="/home/front/assets/images/gotosample-logo.png"
          className="main-logo"
          width="210"
          alt="gotosample"
        />
      </div>
      {progressBar}
      {isLocationForm && !isQuestionForm && !isThankYouForm && (
        <div className="d-flex container justify-content-center mt-5 w-60 mb-auto ">
          <div className="col-md-6">
            <img
              src="/home/front/assets/images/location.png"
              width={400}
              alt="gotosample"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-gotosample">Little more about you?</h3>
            <p className="text-gotosample mb-3">
              Knowing following details helps us serve you targeted surveys, polls and others.
            </p>
            <p className="text-gotosample mb-2">
              Location
            </p>
            <Formik
              enableReinitialize={true}
              initialValues={{
                country: undefined,
                zipcode: undefined,
                city: undefined,
                occupation: undefined,
                mobile: undefined,
              }}
              validate={(values) => {
                const errors: FormikErrors<{
                  country?: string;
                  zipcode?: string;
                  city?: string;
                  occupation?: number;
                  mobile?: string;
                }> = {};
                if (!values.country) {
                  errors.country = "Country is required";
                }
                if (!values.zipcode) {
                  errors.zipcode = "Zipcode is required";
                }
                // } else if (!/^[0-9]{6}$/i.test(values.zipcode)) {
                //   errors.zipcode = "Invalid Zip Code";
                // }
                if (!values.city) {
                  errors.city = "City is required";
                }
                if (!values.occupation) {
                  errors.occupation = "Occupation is required";
                }
                return errors;
              }}
              onSubmit={(
                values: { country?: string; zipcode?: string; city?: string, occupation?: string, mobile?: string },
                { setSubmitting, setFieldError }
              ) => {
                setSubmitting(false);
                const personalInfo: PersonalInfo = {
                  postalCode: values.zipcode,
                  cityName: values.city,
                  occupationID: values.occupation,
                  phone: values.mobile,
                  countryID: values.country,
                };
                saveUserInfo(
                  personalInfo,
                  auth.token ? auth.token : Cookies.get("token")
                ).then((resp) => {
                  if (resp?.status === 0) {
                    if(resp.messageCode === 'INACTIVE_COUNTRY'){
                      const token = Cookies.get("token");
                      logout(auth.token ? auth.token : token);
                      Cookies.remove("token");
                      navigate("/registration-terminate");
                    } else
                    setFieldError("city", resp?.message);
                  } else {
                    setIsLocationForm(false);
                    setIsQuestionForm(true);
                    setIsThankYouForm(false);
                  }
                });
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                isValid,
                dirty,
                setFieldValue,
                setFieldError,
              }) => (
                <Form>
                  <div className="pf-form__textfield mb-3">
                    <Field
                      as="select"
                      name="country"
                      placeholder="Country"
                      className="form-control"
                      onChange={(e: any) => {
                        handleChange("country")(e);
                        const allowedCountry = ["Germany", "France", "Italy", "Spain", "Turkey", "United Kingdom", "Portugal", "China", "Japan", "Saudi Arabia", "India", "United States", "Singapore", "Australia", "Brazil", "Mexico", "United Arab Emirates", "South Africa"];
                        if(allowedCountry.indexOf(e.target.options[e.target.selectedIndex].text) < 0){
                          Swal.fire("Curently not accepting registration from your country, we will notify you once the registration is open");
                          setZipCodeReadOnly(true);
                          setFieldValue("city", 'NA');
                          setFieldValue("zipcode", 'NA');
                        } else {
                          setZipCodeReadOnly(false);
                          setFieldValue("city", '');
                          setFieldValue("zipcode", '');
                        }
                      }}
                      onBlur={handleBlur}
                      defaultValue={values.country}
                    >
                      <option value="" hidden>
                        <span style={{ color: "#B3B3B3" }}>Country</span>
                      </option>
                      {countryList?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                    />
                  </div>
                  <div className="d-flex">
                    <div className="pf-form__textfield mr-4 w-50">
                      <Field
                        className="pf-form__textfield__input_updated"
                        name="zipcode"
                        maxLength={10}
                        placeholder="Zip Code"
                        onChange={handleChange}
                        onBlur={async (e: any) => {
                          handleBlur("zipcode")(e);
                          const val = e?.currentTarget?.value;
                          if (val?.length >= 2 && val?.trim().toLowerCase() != 'na') {
                            //const postalResp = await getPostalCode(
                            getPostalCode(
                              values.country,
                              val,
                              auth.token ? auth.token : Cookies.get("token")
                            ).then((resp) => {
                              if (resp?.status === 1)
                                setFieldValue("city", resp?.payload?.city);
                              else {
                                setFieldValue("city", '');
                                setFieldError("city", resp?.message);
                              }
                            });
                          }
                        }}
                        onKeyDown={handleChange}
                        value={values.zipcode}
                        readOnly={isZipCodeReadOnly}
                      />
                      <ErrorMessage
                        name="zipcode"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                    </div>
                    <div className="pf-form__textfield w-50">
                      <Field
                        className="pf-form__textfield__input_updated"
                        name="city"
                        placeholder="City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        readOnly={true}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                    </div>
                  </div>
                  <p className="text-gotosample mb-2">
                    Occupation
                  </p>
                  <div className="text-left d-flex justify-content-between">
                      <div className="d-flex flex-column w-100 mb-3">
                        <Field
                          as="select"
                          name="occupation"
                          placeholder="Occupation"
                          className="form-control mt-auto"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled selected hidden>
                            <span style={{ color: "#B3B3B3" }}>Occupation</span>
                          </option>
                          {occupationTypeList?.map((occupation) => (
                            <option key={occupation.id} value={occupation.id}>
                              {occupation.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="occupation"
                          component="div"
                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                        />
                      </div>
                  </div>
                  <p className="text-gotosample mb-2">
                    Mobile Number (Optional)
                  </p>
                  <div className="text-left mb-4">
                    <div className="pf-form__textfield">
                      <Field
                        className="form-control  w-100"
                        name="mobile"
                        maxLength={10}
                        placeholder="Mobile Number"
                        onChange={handleChange}
                        onKeyDown={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                    </div>
                  </div>
                  <div className="pf-form__textfield1 w-50 mt-3">
                    <SignUpButtonStyled
                      type="submit"
                      className="btnOrngGrd text-white p-2 pl-5 pr-5 btnArwF"
                      style={{ fontWeight: 700 }}
                      disabled={!(dirty && isValid)}
                    >
                      Continue
                    </SignUpButtonStyled>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {!isLocationForm && isQuestionForm && !isThankYouForm && (
        <div className="d-flex flex-column justify-content-center mt-5 mb-auto mr-auto ml-auto w-50">
          <div className="d-flex flex-column justify-content-center">
            <Formik
              initialValues={{
                [questions[activeQuestion]?.variableName]: "",
              }}
              validate={(values) => {
                const errors: FormikErrors<any> = {};
                return errors;
              }}
              onSubmit={(values: {}, { setSubmitting, setFieldError }) => {
                setSubmitting(false);
                setActiveQuestion((prevState) => prevState + 1);
                updateProfile(
                  Object.keys(formValues)?.[0],
                  Object.values(formValues)?.[0] as string,
                  Cookies.get("token") || auth.token
                ).then((resp) => {
                  if (resp?.status === 1) {
                    if (questions?.length === activeQuestion + 1) {
                      setIsLocationForm(false);
                      setIsQuestionForm(false);
                      setActiveQuestion((prevState) => prevState + 1);
                      setIsThankYouForm(true);
                    }
                  } else {
                    setFieldError("questionError", resp?.message);
                  }
                });
              }}
            >
              {() => (
                <Form className="">
                  <h2 className=" text-gotosample mb-3 d-flex justify-content-center">
                  {questions[activeQuestion]?.title}
                </h2>
                  <div className="pf-form__textfield mb-4 d-flex justify-content-center">
                    {questions[activeQuestion]?.controlType ===
                      FieldType.CHECKBOX && (
                      <div className="mb-2 row">
                        {questions[activeQuestion]?.options?.map(
                          (option, i) => (
                            <span key={option?.key} className="col-md-4 d-flex">
                              <input
                                type="checkbox"
                                id={
                                  "name" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                                name={
                                  "name" +
                                  questions[activeQuestion]?.variableName
                                }
                                className="mr-2 mb-auto mt-1"
                                role="button"
                                onChange={(e: any) =>
                                  onChangeFields(
                                    FieldType.CHECKBOX,
                                    e,
                                    questions[activeQuestion],
                                    option?.key
                                  )
                                }
                              />
                              <label
                                role="button"
                                className="mr-3 text-gotosample text-left"
                                htmlFor={
                                  "name" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                                id={
                                  "label" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                              >
                                {option?.value}
                              </label>
                            </span>
                          )
                        )}
                      </div>
                    )}
                    {questions[activeQuestion]?.controlType ===
                      FieldType.DROPDOWN && (
                      <div className="w-25 mb-2 text-gotosample">
                        <select
                          name={
                            "name" + questions[activeQuestion]?.variableName
                          }
                          id={"name" + questions[activeQuestion]?.variableName}
                          style={{ height: 45 }}
                          onChange={(e: any) =>
                            onChangeFields(
                              FieldType.DROPDOWN,
                              e,
                              questions[activeQuestion]
                            )
                          }
                        >
                          {questions[activeQuestion]?.options?.map((option) => (
                            <option
                              key={option?.key}
                              value={option?.value}
                              className="text-left"
                            >
                              {option?.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {questions[activeQuestion]?.controlType ===
                      FieldType.RADIO && (
                      <div className="mb-2 row justify-content-center w-100">
                        {questions[activeQuestion]?.options?.map(
                          (option, i) => (
                            <div
                              key={option?.key}
                              className={
                                "d-flex mt-2 radio_button mr-4 " +
                                (questions[activeQuestion]?.options?.length > 20
                                  ? " col-md-3"
                                  : questions[activeQuestion]?.options?.length >
                                    10
                                  ? " col-md-12"
                                  : " col-md-12")
                              }
                            >
                              <input
                                type="radio"
                                name={
                                  "name" +
                                  questions[activeQuestion]?.variableName
                                }
                                id={
                                  "name" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                                className=""
                                role="button"
                                onChange={(e: any) =>
                                  onChangeFields(
                                    FieldType.RADIO,
                                    e,
                                    questions[activeQuestion],
                                    option?.key
                                  )
                                }
                              />
                              <label
                                role="button"
                                className="text-break text-gotosample text-left"
                                htmlFor={
                                  "name" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                                id={
                                  "label" +
                                  questions[activeQuestion]?.variableName +
                                  option?.key
                                }
                              >
                                {option?.value}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {questions[activeQuestion]?.controlType ===
                      FieldType.TEXT && (
                      <div className="mb-2">
                        <input
                          type="text"
                          name={
                            "name" + questions[activeQuestion]?.variableName
                          }
                          id={"name" + questions[activeQuestion]?.variableName}
                          className="mr-2"
                          onChange={(e: any) =>
                            onChangeFields(
                              FieldType.TEXT,
                              e,
                              questions[activeQuestion]
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  <ErrorMessage
                    name="questionError"
                    component="div"
                    className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                  />
                  <div className="pf-form__textfield1 mt-2">
                    <div className="alnR mr-2">
                      {/* <button
                        className="btnBlueGrd exPDBtn mr-2 btnArwB"
                        style={{ fontWeight: 700 }}
                        onClick={() => {
                          if (activeQuestion > 1) {
                            setActiveQuestion((prevState) => prevState - 2);
                          } else {
                            setIsLocationForm(true);
                            setIsQuestionForm(false);
                            setIsThankYouForm(false);
                          }
                        }}
                      >
                        Back
                      </button> */}
                      <ContinueStyled
                        type="submit"
                        className="btnOrngGrd exPDBtn btnArwF"
                        style={{ fontWeight: 700 }}
                      >
                        Continue
                      </ContinueStyled>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {!isLocationForm && !isQuestionForm && isThankYouForm && (
        <div className="d-flex container justify-content-center mt-5 w-60 mb-auto">
          <div className="col-md-6">
            <img
              src="/home/front/assets/images/Thankyou.png"
              height={250}
              alt="gotosample"
            />
          </div>
          <div className="col-md-6">
          <Formik
              enableReinitialize={true}
              initialValues={{
                referralCode: undefined
              }}
              validate={(values) => {
                const errors: FormikErrors<{
                  referralCode?: string;
                }> = {};
                let rC = values?.referralCode ? values?.referralCode : '';
                if (rC !== "" && rC.length < 6) {
                  errors.referralCode = "Invalid referral code";
                }
              }}
              onSubmit={(values: {referralCode?: string}, 
                { setSubmitting, setFieldError }) => {
                setSubmitting(false);
                if(values.referralCode !== ""){
                  let rC = values?.referralCode ? values?.referralCode : '';
                  if(rC !== ""){
                    if(rC.length === 6){
                      updateReferralCode(
                        values.referralCode,
                        auth.token ? auth.token : Cookies.get("token")
                      )
                      .then((resp) => {
                        if (resp?.status === 0) {
                          Swal.fire(resp?.message);
                        } else {
                          navigate("/dashboard")
                        }
                      });
                    } else
                    Swal.fire('Invalid referral code 2');
                  } else 
                  navigate("/dashboard")
                } else {
                  navigate("/dashboard")
                }
              }}
            >
              {() => (
                <Form className="">
                  <h3 className=" text-gotosample mb-5 d-flex">
                    You're on your way!
                  </h3>
                  <p className="text-white">
                    Please enter the Referral Code if you have any
                  </p>
                  <div className="w-100  d-flex">
                    <div className="pf-form__textfield">
                      <Field
                        className="form-control w-100"
                        name="referralCode"
                        type="text"
                        maxLength="6"
                        placeholder="Referral Code"
                      />
                      <ErrorMessage
                        name="referralCode"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                    </div>
                  </div>
                  <div className="w-100  d-flex">
                    <SignUpButtonStyled
                      type="submit"
                      className="btnOrngGrd text-white p-2 pl-5 pr-5 btnArwF"
                      style={{ fontWeight: 700 }}
                    >
                      Go to Dashboard
                    </SignUpButtonStyled>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
